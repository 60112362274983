import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { pfp } from '../../images';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

const Title = 'Dylon Tjanaka | Homepage';

function Home(props) {
  useEffect(() => {
    // https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
    props.changeHeader(Title);
  });

  return (
    <>
      <Helmet defer={false}>
        <title>{Title}</title>
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
        />
      </Helmet>
      <div className='content'>
        <Paper elevation={4} square={true} className='contentPaper'>
          <h1 className='contentTitle'>Dylon Tjanaka</h1>
          <a id='pfpLink' href={pfp}>
            <img
              id='pfp'
              className='center responsive'
              src={pfp}
              alt='me sitting on a staircase at Walt Disney Concert Hall'
            />
          </a>
          <p className='textBlock'>
            Welcome to my page! I am a Master's student studying CS at UCLA. My
            hometown is Santa Clara, California. My interests include algorithms
            and machine learning and my hobbies include folding origami, cubing
            (solving Rubik's cubes), and cycling.
          </p>
          <button
            onClick={addRandomPrequelQuote}
            className='center miscButton blue odd'
          >
            Click here for a prequels quote!
          </button>
          <div id='contentContainer'></div>
          <Link to='/widgets'>
            <button id='widgets' className='center miscButton blue even'>
              Click here to see some JS widgets!
            </button>
          </Link>
          <br />
          <p>Or click the links below to learn more about me!</p>
          <br />
          <div id='links' className='row'>
            <div className='col-3'>
              <Link to='/robotics'>
                <button className='interestButton odd'>Robotics</button>
              </Link>
            </div>
            <div className='col-3'>
              <Link to='/micromouse'>
                <button className='interestButton even'>Micromouse</button>
              </Link>
            </div>
            <div className='col-3'>
              <Link to='/cubing'>
                <button className='interestButton odd'>Cubing</button>
              </Link>
            </div>
            <div className='col-3'>
              <Link to='/printing'>
                <button className='interestButton even'>3D Printing</button>
              </Link>
            </div>
          </div>
          <div id='links-2' className='row'>
            <div className='col-3'>
              <Link to='/misc'>
                <button id='miscLink' className='interestButton even'>
                  Miscellaneous
                </button>
              </Link>
            </div>
            <div className='col-9'></div>
          </div>
          <div id='socialMedia-1' className='row'>
            <br />
            <div id='facebook' className='col-3'>
              <a
                href='https://www.facebook.com/profile.php?id=100014709977810'
                target='_blank'
                rel='noopener noreferrer'
                className='fa fa-facebook'
                style={{ fontSize: '50px' }}
              >
                {' '}
              </a>
            </div>
            <div id='instagram' className='col-3'>
              <a
                href='https://www.instagram.com/dylon.tjanaka/'
                target='_blank'
                rel='noopener noreferrer'
                className='fa fa-instagram'
                style={{ fontSize: '50px' }}
              >
                {' '}
              </a>
            </div>
            <div id='linkedin' className='col-3'>
              <a
                href='https://www.linkedin.com/in/dtjanaka'
                target='_blank'
                rel='noopener noreferrer'
                className='fa fa-linkedin'
                style={{ fontSize: '50px' }}
              >
                {' '}
              </a>
            </div>
            <div id='github' className='col-3'>
              <a
                href='https://github.com/dtjanaka/'
                target='_blank'
                rel='noopener noreferrer'
                className='fa fa-github'
                style={{ fontSize: '50px' }}
              >
                {' '}
              </a>
            </div>
          </div>
          <div id='socialMedia-2' className='row'>
            <div id='youtube-play' className='col-3'>
              <a
                href='https://www.youtube.com/channel/UCx1tcHdDx4esRDbmDgRIn9Q'
                target='_blank'
                rel='noopener noreferrer'
                className='fa fa-youtube-play'
                style={{ fontSize: '50px' }}
              >
                {' '}
              </a>
            </div>
            <div className='col-9'></div>
          </div>
          <br />
        </Paper>
      </div>
    </>
  );
}

/**
 * Adds a random quote to the page.
 */
function addRandomPrequelQuote() {
  const quotes = [
    'Hello there.',
    'There’s always a bigger fish.',
    'I don’t like sand. It’s coarse and rough and irritating and it gets' +
      ' everywhere.',
    'Now this is podracing!',
    'I don’t care what universe you’re from, that’s got to hurt!',
    'I sense Count Dooku.',
    'His cells have the highest concentration of midi-chlorians I have' +
      ' seen in a life-form.',
    'I AM the Senate!',
    'I’m just a simple man, trying to make my way in the universe.',
    'This is getting out of hand! Now, there are two of them!',
  ];

  const quoteContainer = document.getElementById('contentContainer');
  quoteContainer.style.display = 'block';
  quoteContainer.style.textAlign = 'center';

  // Pick a random different quote.
  let quote = quoteContainer.innerText;
  while (quote === quoteContainer.innerText) {
    quote = quotes[Math.floor(Math.random() * quotes.length)];
  }

  // Add it to the page.
  quoteContainer.innerHTML = '<br /><p>' + quote + '</p><br />';
}

export default Home;
