import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const Title = 'Dylon Tjanaka | tiny-basketball';

const useStyles = makeStyles((theme) => ({
  tinyBasketballiFrame: {
    width: '100%',
    height: '100vh',
    border: 'none',
    // https://stackoverflow.com/a/12726445
    verticalAlign: 'bottom',
  },
}));

function TinyBasketball(props) {
  const classes = useStyles();

  return (
    <>
      <Helmet defer={false}>
        <title>{Title}</title>
      </Helmet>
      <iframe
        src='https://tiny-basketball-mirror.netlify.app'
        className={classes.tinyBasketballiFrame}
        title='tiny-basketball game'
      ></iframe>
    </>
  );
}

export default TinyBasketball;
