export const logo = require('./logo/logo_4.svg');
export const logotype = require('./logo/logotype_2_no_font.svg');

export const fb = require('./socials/fb.svg');
export const ig = require('./socials/ig.svg');
export const li = require('./socials/li.svg');
export const gh = require('./socials/gh.svg');
export const yt = require('./socials/yt.svg');

export const print1 = require('./printing/IMG_0663.jpg');
export const print2 = require('./printing/IMG_1814.jpg');
export const print3 = require('./printing/IMG_0593.jpg');
export const print4 = require('./printing/IMG_0926.jpg');

export const cubing1 = require('./cubing/IMG_0111.jpg');

export const rat = require('./micromouse/IMG_1594.jpg');
export const mm = require('./micromouse/IMG_2326.jpg');

export const map1 = require('./cycling/IMG_2686.jpg');
export const map2 = require('./cycling/IMG_2744.jpg');
export const map3 = require('./cycling/IMG_2751.jpg');

export const I8732 = require('./bridge/IMG_8732.jpg');
export const I8795 = require('./bridge/IMG_8795.jpg');
export const I8819 = require('./bridge/IMG_8819.jpg');

export const I2715 = require('./bridge/IMG_2715.jpg');
export const I2718 = require('./bridge/IMG_2718.jpg');
export const I2722 = require('./bridge/IMG_2722.jpg');

export const pfp = require('./pfps/IMG_2581_cropped.jpg');

export const angular = require('./misc/angular.png');
