import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const Title = 'Dylon Tjanaka | Hint';

const useStyles = makeStyles((theme) => ({
  hintIframe: {
    width: '100%',
    height: '100vh',
    border: 'none',
    // https://stackoverflow.com/a/12726445
    verticalAlign: 'bottom',
  },
}));

function Hint(props) {
  const classes = useStyles();

  return (
    <>
      <Helmet defer={false}>
        <title>{Title}</title>
      </Helmet>
      <iframe
        src='/hintellectuals/hint.html'
        className={classes.hintIframe}
        title='Hint chart'
      ></iframe>
    </>
  );
}

export default Hint;
