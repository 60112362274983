import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Title = 'Dylon Tjanaka | About Website';

function About(props) {
  useEffect(() => {
    props.changeHeader(Title);
  });

  return (
    <>
      <Helmet defer={false}>
        <title>{Title}</title>
      </Helmet>
      <div className='content'>
        <br />
        <h1 className='contentTitle'>About Website</h1>
        <hr />
        <br />
        <p>
          Built with Java 8 servlets, React, and Three.js and deployed on Google
          App Engine.
        </p>
      </div>
    </>
  );
}

export default About;
