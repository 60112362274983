import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';

import { logo, pfp } from '../images';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
//import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
// alternative calendar icons
//import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
//import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import SettingsIcon from '@mui/icons-material/Settings';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { ClassSchedules, QuarterLabels } from './Constants';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import Avatar from '@material-ui/core/Avatar';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import Badge from '@material-ui/core/Badge';

import Chip from '@material-ui/core/Chip';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import SpeedDial from '@material-ui/lab/SpeedDial';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import EmojiEmotionsRoundedIcon from '@material-ui/icons/EmojiEmotionsRounded';
import WidgetsRoundedIcon from '@material-ui/icons/WidgetsRounded';

import SvgIcon from '@material-ui/core/SvgIcon';
import DirectionsBikeRoundedIcon from '@material-ui/icons/DirectionsBikeRounded';
import DirectionsRunRoundedIcon from '@material-ui/icons/DirectionsRunRounded';
import SportsBasketballRoundedIcon from '@material-ui/icons/SportsBasketballRounded';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '48px',
    height: '48px',
  },
  // had to add height restriction to second (nonvisible) toolbar
  fixedPlacementToolbarRegular: {
    minHeight: '48px',
  },
  menuButtonFocused: {
    // this took way too long to figure out
    // wanted to reduce the maximum size of the ripple of the menu button since the primary appbar height was reduced from 64px to 48px
    '& > .MuiTouchRipple-root': {
      width: '40px',
      height: '40px',
      top: '4px',
      left: '4px',
    },
  },
  title: {
    flexGrow: 1,
    marginLeft: '15px',
  },
  homeIcon: {
    width: '27px',
    display: 'block',
  },
  drawerPAL: {
    backgroundColor: theme.palette.secondary.main,
  },
  boxes: {
    width: '250px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'start',
  },
  box: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black',
  },
  boxTop: {
    // 1.5px since the borders seem to overlap slightly
    borderTop: '1.5px solid black',
  },
  boxBottom: {
    borderBottom: '1.5px solid black',
  },
  boxLeft: {
    borderLeft: '1.5px solid black',
  },
  boxRight: {
    borderRight: '1.5px solid black',
  },
  modalChild: {
    width: '400px',
    height: '400px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-200px',
    marginTop: '-200px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  boxButton: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    color: 'black',
    border: 'none',
    cursor: 'pointer',
    // button:hover --> opacity: 0.7 already in App.css
  },
  linkButton: {
    height: '40px',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  showBottomShadow: {
    marginBottom: '10px !important',
  },
  tabsContainer: {
    width: '250px',
  },
  tabsAppBar: {
    height: '50px',
  },
  tabsAppBarRoot: {
    width: '250px',
    position: 'sticky',
    top: 0,
  },
  tabRoot: {
    minWidth: 0,
    height: '50px',
  },
  tabPanel: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  accordion: {
    backgroundColor: 'transparent !important',
  },
  accordionSummary: {
    height: '40px',
    color: 'black',
    backgroundColor: 'transparent !important',
  },
  accordionDetails: {
    backgroundColor: 'transparent !important',
    color: 'black',
  },
  expandIcon: {
    color: 'black',
  },
  bottomNavContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 50px)', // height of viewport - height of tab AppBar
  },
  bottomNavDiv: {
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    // https://stackoverflow.com/questions/11258877/fixed-element-disappears-in-chrome
    //transform: 'translateZ(0)',
    //WebkitTransform: 'translateZ(0)',
  },
  bottomNavigation: {
    height: '50px',
    backgroundColor: theme.palette.secondary.main,
    color: 'black',
  },
  topBottomNav: {
    // minimum border-width on Chrome seems to be 0.667px
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.12)',
  },
  bottomBottomNav: {
    borderTop: '0.5px solid rgba(0, 0, 0, 0.12)',
  },
  bottomNavActionRoot: {
    minWidth: 0,
  },
  bottomNavActionSelected: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 50,
    height: '37.5px',
    display: 'flex',
    justifyContent: 'center',
  },
  toggleButtonSelected: {
    // is there a way to do this without !important?
    color: 'black !important',
    fontWeight: 'bold',
  },
  timelineContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 150px)',
  },
  classTimeline: {
    paddingTop: '42px',
  },
  profileRowDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  smallAvatar: {
    height: '36px',
    width: '36px',
  },
  avatarName: {
    color: 'black',
    marginLeft: '9px',
    marginRight: '2.5px',
  },
  attributeChip: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    height: '25px',
    border: '2px solid black',
    borderRadius: '5px',
    marginTop: '10px',
    marginLeft: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successChip: {
    border: '2px solid #07cd0f',
    backgroundColor: '#9cfca0',
    color: '#036e08',
  },
  inProgresChip: {
    border: '2px solid #bdc506',
    backgroundColor: '#f9fcaf',
    color: '#6a6e03',
  },
  infoBoxChip: {
    display: 'flex',
    flexFlow: 'column',
    borderRadius: '25px',
    height: '87px',
    backgroundColor: theme.palette.secondary.main,
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '6px', // 5px + 1px for top line of accordion
    border: '2px solid black',
  },
  locationHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: '50px',
    marginTop: '-2px',
  },
  locationChipIcon: {
    marginLeft: '18px',
  },
  locationChipText: {
    paddingLeft: '2px',
  },
  oneLineDescription: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    height: '37px',
    marginBottom: '-2px',
    paddingLeft: '22px',
  },
  favoriteColorBox: {
    width: '100%',
    height: '50px',
    backgroundColor: '#50C878',
  },
  settingsContainer: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 50px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  directoryTreeViewRoot: {
    marginLeft: '5px',
  },
  treeFolderLabel: {
    paddingLeft: '12px',
  },
  pageLinkButtonLabelText: {
    paddingLeft: '12px',
  },
  pageLinkButtonLabel: {
    paddingLeft: '21px',
  },
}));

const ThickTreeItem = withStyles((theme) => ({
  label: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    height: '40px',
  },
  group: {
    marginLeft: '20px',
  },
  iconContainer: {
    marginRight: '5px',
  },
}))(TreeItem);

const RippleBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',

      // this solves the issue of the ripple not being centered
      boxSizing: 'inherit',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const ChipBadge = withStyles((theme) => ({
  root: {
    width: '100%',
  },
  badge: {
    right: 15,
    top: 15,
  },
}))(Badge);

let openXFlag = false; // global variable since setting and using state immediately doesn't work

function GenerateClassTimeline(daySchedule) {
  const classes = useStyles();

  return (
    <Timeline className={classes.classTimeline}>
      {daySchedule.length ? (
        daySchedule.map((x) => (
          <TimelineItem>
            <TimelineOppositeContent>
              <Tooltip title={x.start + ' – ' + x.end} arrow={true}>
                <Typography variant='body2'>{x.start}</Typography>
              </Tooltip>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color='grey' />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {/* https://stackoverflow.com/questions/60598842/how-to-make-line-break-for-tooltip-titles-in-material-ui */}
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {x.name + '\n' + x.sub + '\n' + x.loc}
                  </span>
                }
                arrow={true}
                placement='bottom-end'
              >
                <Typography variant='body2'>{x.class}</Typography>
              </Tooltip>
            </TimelineContent>
          </TimelineItem>
        ))
      ) : (
        <Typography variant='body2' style={{ textAlign: 'center' }}>
          No classes to show!
        </Typography>
      )}
    </Timeline>
  );
}

function ClassSchedule(props) {
  const classes = useStyles();

  // https://davidwalsh.name/destructuring-alias
  const { bottomNavValue1: quarter, bottomNavValue2: year } = props;

  const [day, setDay] = React.useState(0); // 01234 = MTWRF
  const [quarterIndex, setQuarterIndex] = React.useState(0);

  const handleDay = (event, newDay) => {
    if (newDay !== null) {
      setDay(newDay);
    }
  };

  React.useEffect(() => {
    setQuarterIndex(3 * year + quarter);
  }, [quarter, year]);

  return (
    <div className={classes.timelineContainer}>
      {GenerateClassTimeline(ClassSchedules[QuarterLabels[quarterIndex]][day])}
      <ToggleButtonGroup
        value={day}
        exclusive
        onChange={handleDay}
        aria-label='day of the week'
        className={classes.toggleContainer}
      >
        <ToggleButton
          value={0}
          aria-label='Monday'
          classes={{ selected: classes.toggleButtonSelected }}
        >
          M
        </ToggleButton>
        <ToggleButton
          value={1}
          aria-label='Tuesday'
          classes={{ selected: classes.toggleButtonSelected }}
        >
          T
        </ToggleButton>
        <ToggleButton
          value={2}
          aria-label='Wednesday'
          classes={{ selected: classes.toggleButtonSelected }}
        >
          W
        </ToggleButton>
        <ToggleButton
          value={3}
          aria-label='Thursday'
          classes={{ selected: classes.toggleButtonSelected }}
        >
          R
        </ToggleButton>
        <ToggleButton
          value={4}
          aria-label='Friday'
          classes={{ selected: classes.toggleButtonSelected }}
        >
          F
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

const yearLabels = ['1', '2', '3', 'M'];
const quarterLabels = ['F', 'W', 'S'];

function FixedBottomNavigation(props) {
  const classes = useStyles();

  const { children, labels1, labels2 } = props;

  const [bottomNavValue1, setBottomNavValue1] = React.useState(0);
  const [bottomNavValue2, setBottomNavValue2] = React.useState(0);
  const bottomNavRef = React.useRef(null);

  React.useEffect(() => {
    // scroll back to top when different tab selected
    bottomNavRef.current.ownerDocument.body.scrollTop = 0;
  }, [bottomNavValue1, bottomNavValue2]);

  return (
    <div ref={bottomNavRef} className={classes.bottomNavContainer}>
      {/* pass bottomNavValues to ClassSchedule */}
      {/* https://stackoverflow.com/a/35102287 */}
      {React.cloneElement(React.Children.only(children), {
        bottomNavValue1: bottomNavValue1,
        bottomNavValue2: bottomNavValue2,
      })}

      <div className={classes.bottomNavDiv}>
        <BottomNavigation
          value={bottomNavValue1}
          onChange={(event, newValue) => {
            setBottomNavValue1(newValue);
          }}
          className={`${classes.bottomNavigation} ${classes.topBottomNav}`}
        >
          {labels1.map((x) => (
            <BottomNavigationAction
              label={x}
              showLabel={true}
              classes={{ selected: classes.bottomNavActionSelected }}
            />
          ))}
        </BottomNavigation>
        <BottomNavigation
          value={bottomNavValue2}
          onChange={(event, newValue) => {
            setBottomNavValue2(newValue);
          }}
          className={`${classes.bottomNavigation} ${classes.bottomBottomNav}`}
        >
          {labels2.map((x) => (
            <BottomNavigationAction
              label={x}
              showLabel={true}
              classes={{
                root: classes.bottomNavActionRoot,
                selected: classes.bottomNavActionSelected,
              }}
            />
          ))}
        </BottomNavigation>
      </div>
    </div>
  );
}

FixedBottomNavigation.propTypes = {
  children: PropTypes.element,
};

// children is a special prop
function TabPanel(props) {
  const classes = useStyles();

  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={`${classes.tabPanel} ${
        classes['tabPanel' + index] ? classes['tabPanel' + index] : ''
      }`}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// accessibility properties for tabs
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function Header(props) {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalText, setModalText] = React.useState('');
  const [buttonNum, setButtonNum] = React.useState(0);
  const [intervalID, setIntervalID] = React.useState(0);
  const [tabValue, setTabValue] = React.useState(0);
  const [accordionExpanded, setAccordionExpanded] = React.useState(false);
  const [rating, setRating] = React.useState(0);

  const toggleDrawer = (open) => (event) => {
    if (
      // what is this for?
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  function PageLinkButton(props) {
    const classes = useStyles();

    const { label, to, buttonClassName } = props;

    return (
      <>
        {/* href reloads the page; Link does not */}
        <Link to={to}>
          {/* https://stackoverflow.com/questions/25158435/paper-button-always-as-upper-case */}
          <Button
            variant='contained'
            color='secondary'
            fullWidth='true'
            className={buttonClassName || classes.linkButton}
            classes={{ label: classes.pageLinkButtonLabel }}
            style={{
              textTransform: 'none',
              fontWeight: 400,
              lineHeight: 1.5,
              justifyContent: 'start',
            }}
            onClick={toggleDrawer(false)}
            disableElevation={true}
          >
            {label}
          </Button>
        </Link>
      </>
    );
  }

  // credit: https://www.w3schools.com/howto/howto_js_countdown.asp
  function generateTimeStrings(numMsecs) {
    const days = Math.floor(numMsecs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (numMsecs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((numMsecs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((numMsecs % (1000 * 60)) / 1000);

    const daysString = days + (days === 1 ? ' day' : ' days');
    const hoursString = hours + (hours === 1 ? ' hour' : ' hours');
    const minutesString = minutes + (minutes === 1 ? ' minute' : ' minutes');
    const secondsString = seconds + (seconds === 1 ? ' second' : ' seconds');

    return [daysString, hoursString, minutesString, secondsString];
  }

  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/accessible-emoji.md
  const xNumsInfo = {
    420: {
      month: 3,
      day: 20,
      dayOfText: (
        <h1>
          <span role='img' aria-label='smiling face with sunglasses'>
            😎
          </span>{' '}
          4/20{' '}
          <span role='img' aria-label='smiling face with sunglasses'>
            😎
          </span>
        </h1>
      ),
      untilText: <h1>until 4/20</h1>,
    },
    1225: {
      month: 11,
      day: 25,
      dayOfText: (
        <h1>
          <span role='img' aria-label='Christmas tree'>
            🎄
          </span>{' '}
          Merry Christmas!{' '}
          <span role='img' aria-label='Christmas tree'>
            🎄
          </span>
        </h1>
      ),
      untilText: <h1>until Christmas</h1>,
    },
  };

  function callbackX(num) {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (month === xNumsInfo[num].month && day === xNumsInfo[num].day) {
      setModalText(xNumsInfo[num].dayOfText);
      return;
    }

    let goalDate;
    if (
      month > xNumsInfo[num].month ||
      (month === xNumsInfo[num].month && day > xNumsInfo[num].day)
    ) {
      goalDate = new Date(
        year + 1,
        xNumsInfo[num].month,
        xNumsInfo[num].day
      ).getTime();
    } else {
      goalDate = new Date(
        year,
        xNumsInfo[num].month,
        xNumsInfo[num].day
      ).getTime();
    }

    const difference = goalDate - date.getTime();

    let daysString, hoursString, minutesString, secondsString;
    [daysString, hoursString, minutesString, secondsString] =
      generateTimeStrings(difference);

    const countdownString = (
      <div>
        <h1>{daysString}</h1>
        <h1>{hoursString}</h1>
        <h1>{minutesString}</h1>
        <h1>{secondsString}</h1>
        {xNumsInfo[num].untilText}
      </div>
    );

    setModalText(countdownString);
  }

  function toggleModal(open, num) {
    setModalOpen(open);
    if (open) {
      setButtonNum(num);
    }
    if (xNumsInfo.hasOwnProperty(num)) {
      openXFlag = !openXFlag;
      if (!openXFlag) {
        clearInterval(intervalID);
      } else {
        callbackX(num);
        setIntervalID(setInterval(() => callbackX(num), 1000)); // remember to use anonymous functions for callbacks with arguments!
      }
    } else {
      setModalText(<h1>{boxModalContent[num]}</h1>);
    }
  }

  // note 1: this is run multiple times when loading a page or switching between pages (via React Router)
  const { pathname } = useLocation();

  window.addEventListener('resize', () => {
    if (
      !['/ri3d', '/hint', '/tiny-basketball', '/snake-origins'].includes(
        pathname
      )
    ) {
      if (window.innerWidth < 600) {
        document.getElementById('headerTitle').innerHTML = 'Dylon Tjanaka';
      } else {
        document.getElementById('headerTitle').innerHTML = props.name;
      }
    }
  });

  // instead of window.addEventListener('load', ...), which fires twice
  // note 2: this is run once per page load, meaning switching between pages via React Router does not rerun this
  //         additionally, only one window.onload function is run if there are multiple defined
  //         (e.g. multiple components have a window.onload function defined)
  /*window.onload = async () => {
  };*/

  // TODO: add a DataGrid or Table to one of the boxes
  // https://v4.mui.com/api/table/
  let boxModalContent = [...Array(10000 + 1).keys()];
  boxModalContent[69] = 'Nice';

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabChangeIndex = (index) => {
    setTabValue(index);
  };

  const handleAccordionChange = (panel) => (event, isAccordionExpanded) => {
    setAccordionExpanded(isAccordionExpanded ? panel : false);
  };

  if (
    ['/ri3d', '/hint', '/tiny-basketball', '/snake-origins'].includes(pathname)
  ) {
    return null;
  } else {
    return (
      <>
        {/* don't need to wrap Header in div */}
        <Modal
          className={classes.modal}
          open={modalOpen}
          onClose={() => toggleModal(false, buttonNum)}
        >
          <div className={classes.modalChild}>{modalText}</div>
        </Modal>
        <Drawer
          anchor='left'
          open={drawerOpen}
          onClose={toggleDrawer(false)} // why do Drawer onClose and onClick functions have to be as such while onClose and onClick functions for Modal have to be wrapped in anonymous functions?
          classes={{ paperAnchorLeft: classes.drawerPAL }} // pull out rule name
        >
          <div className={classes.tabsContainer}>
            <AppBar
              position='static'
              className={classes.tabsAppBar}
              classes={{
                root: classes.tabsAppBarRoot,
              }}
              color='secondary'
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant='fullWidth'
                aria-label='tabs'
                indicatorColor='primary'
              >
                <Tab
                  icon={<FolderRoundedIcon />}
                  {...a11yProps(0)}
                  classes={{ root: classes.tabRoot }}
                />
                <Tab
                  icon={<PersonRoundedIcon />}
                  {...a11yProps(1)}
                  classes={{ root: classes.tabRoot }}
                />
                <Tab
                  icon={<TodayRoundedIcon />}
                  {...a11yProps(2)}
                  classes={{ root: classes.tabRoot }}
                />
                <Tab
                  icon={<QuestionMarkRoundedIcon />}
                  {...a11yProps(3)}
                  classes={{ root: classes.tabRoot }}
                />
                <Tab
                  icon={<SettingsIcon />}
                  {...a11yProps(4)}
                  classes={{ root: classes.tabRoot }}
                />
              </Tabs>
            </AppBar>

            <SwipeableViews
              axis='x'
              index={tabValue}
              onChangeIndex={handleTabChangeIndex}
            >
              <TabPanel value={tabValue} index={0}>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreRoundedIcon />}
                  defaultExpandIcon={<ChevronRightRoundedIcon />}
                  className={classes.directoryTreeViewRoot}
                >
                  <ThickTreeItem
                    nodeId='1'
                    label={
                      <>
                        <SchoolRoundedIcon fontSize='small' />
                        <span className={classes.treeFolderLabel}>
                          Academic
                        </span>
                      </>
                    }
                  >
                    <ThickTreeItem
                      nodeId='2'
                      label={
                        <>
                          <FolderRoundedIcon fontSize='small' />
                          <span className={classes.treeFolderLabel}>
                            Projects
                          </span>
                        </>
                      }
                    >
                      <PageLinkButton
                        to='/tiny-basketball'
                        label={
                          <>
                            <SportsBasketballRoundedIcon fontSize='small' />
                            <span className={classes.pageLinkButtonLabelText}>
                              tiny-basketball
                            </span>
                          </>
                        }
                      />
                      <PageLinkButton
                        to='/snake-origins'
                        label={
                          <>
                            <SvgIcon viewBox='-64 0 512 512' fontSize='small'>
                              <path d='M352 128h-96V32c0-17.67-14.33-32-32-32h-64c-17.67 0-32 14.33-32 32v96H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h96v224c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V256h96c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z' />
                            </SvgIcon>
                            <span className={classes.pageLinkButtonLabelText}>
                              Snake: Origins
                            </span>
                          </>
                        }
                      />
                    </ThickTreeItem>
                  </ThickTreeItem>
                  <ThickTreeItem
                    nodeId='3'
                    label={
                      <>
                        <EmojiEmotionsRoundedIcon fontSize='small' />
                        <span className={classes.treeFolderLabel}>Hobbies</span>
                      </>
                    }
                  >
                    <ThickTreeItem
                      nodeId='4'
                      label={
                        <>
                          <FolderRoundedIcon fontSize='small' />
                          <span className={classes.treeFolderLabel}>
                            Sports
                          </span>
                        </>
                      }
                    >
                      <PageLinkButton
                        to='/cycling'
                        label={
                          <>
                            <DirectionsBikeRoundedIcon fontSize='small' />
                            <span className={classes.pageLinkButtonLabelText}>
                              Cycling
                            </span>
                          </>
                        }
                      />
                      <PageLinkButton
                        to='/running'
                        label={
                          <>
                            <DirectionsRunRoundedIcon fontSize='small' />
                            <span className={classes.pageLinkButtonLabelText}>
                              Running
                            </span>
                          </>
                        }
                      />
                      <PageLinkButton
                        to='/badminton'
                        label={
                          <>
                            <SvgIcon
                              viewBox='0 0 305.422 305.422'
                              fontSize='small'
                            >
                              <path
                                d='M35.727,192.027L16.656,211.1c-21.618,21.611-22.232,56.063-1.399,78.428c0.241,0.26,0.492,0.51,0.751,0.752
	c10.484,9.766,24.155,15.143,38.497,15.143c15.084,0,29.266-5.873,39.932-16.541l19.078-19.078l107.52-43.965
	c0.046-0.018,0.092-0.037,0.138-0.055l57.304-23.432c18.434-7.533,24.439-19.727,26.231-28.627
	c2.857-14.193-3.296-30.525-16.459-43.686c-10.889-10.889-26.24-16.379-42.33-16.133c1.202-14.109-3.35-28.625-13.729-39.859
	c-0.269-0.289-0.548-0.568-0.838-0.838c-11.236-10.381-25.754-14.926-39.861-13.73c0.254-16.102-5.246-31.443-16.134-42.332
	C164.32,6.107,151.056,0,138.692,0c-2.381,0-4.729,0.227-7.02,0.688c-8.901,1.793-21.095,7.797-28.632,26.242L79.657,84.281
	c-0.017,0.039-0.032,0.078-0.048,0.117L35.727,192.027z M111.427,238.246l61.725-47.424l15.746,15.746L111.427,238.246z
	 M87.778,218.584l-0.969-0.969l49.158-63.979l15.791,15.791L87.778,218.584z M67.544,193.449l31.349-76.889l15.68,15.682
	L67.544,193.449z M73.222,267.668c-4.999,5-11.647,7.754-18.718,7.754c-6.549,0-12.798-2.389-17.656-6.736
	c-9.397-10.49-8.99-26.365,1.02-36.373l10.606-10.605l35.355,35.355L73.222,267.668z M267.035,151.254
	c6.631,6.629,8.931,13.225,8.261,16.549c-0.656,3.26-5.4,5.645-8.173,6.779l-48.112,19.672l-21.866-21.865l30.996-23.816
	C242.108,141.484,258.326,142.545,267.035,151.254z M210.55,94.848c7.56,8.619,7.309,21.467-0.536,29.82l-34.264,26.326
	l-21.35-21.35l26.326-34.264C189.08,87.537,201.93,87.289,210.55,94.848z M130.815,38.268c1.131-2.77,3.517-7.514,6.777-8.17
	c3.318-0.668,9.919,1.629,16.548,8.26c8.71,8.711,9.772,24.93,2.682,38.896l-23.815,30.996l-21.826-21.826L130.815,38.268z'
                              />
                            </SvgIcon>
                            <span className={classes.pageLinkButtonLabelText}>
                              Badminton
                            </span>
                          </>
                        }
                      />
                    </ThickTreeItem>
                    <ThickTreeItem
                      nodeId='5'
                      label={
                        <>
                          <FolderRoundedIcon fontSize='small' />
                          <span className={classes.treeFolderLabel}>
                            Creative
                          </span>
                        </>
                      }
                    >
                      <PageLinkButton
                        to='/origami'
                        label={
                          <>
                            <SvgIcon
                              viewBox='0 0 35.761 35.761'
                              fontSize='small'
                            >
                              <path
                                d='M35.666,5.539c-0.211-0.544-0.736-0.903-1.318-0.903H17.882c-0.416,0-0.812,0.183-1.081,0.501
				l-2.672,3.162l-5.7-6.722C8.398,1.54,8.356,1.518,8.322,1.484C8.267,1.433,8.214,1.381,8.15,1.338
				C8.09,1.295,8.025,1.264,7.96,1.232C7.914,1.21,7.878,1.179,7.83,1.161C7.81,1.154,7.789,1.155,7.769,1.149
				C7.697,1.127,7.625,1.117,7.551,1.106c-0.07-0.011-0.138-0.023-0.208-0.023c-0.068,0-0.132,0.013-0.2,0.023
				C7.067,1.118,6.994,1.127,6.92,1.151C6.901,1.157,6.88,1.155,6.861,1.163C6.816,1.18,6.779,1.212,6.735,1.233
				C6.667,1.266,6.601,1.298,6.54,1.342C6.477,1.386,6.425,1.438,6.371,1.49C6.336,1.524,6.293,1.548,6.262,1.586l-5.934,7.12
				c-0.351,0.422-0.427,1.009-0.194,1.505c0.233,0.498,0.732,0.816,1.281,0.816h4.519v7.486c0,0.021,0.008,0.04,0.009,0.06
				c0.003,0.071,0.003,0.141,0.017,0.21c0.007,0.035,0.022,0.065,0.032,0.1c0.011,0.038,0.021,0.075,0.035,0.112
				c0.035,0.097,0.08,0.189,0.136,0.274c0.014,0.022,0.023,0.045,0.038,0.066c0.074,0.102,0.162,0.194,0.261,0.274
				c0.025,0.02,0.053,0.036,0.08,0.054c0.048,0.034,0.094,0.072,0.147,0.101l8.047,4.235l4.84,9.887
				c0.241,0.493,0.739,0.793,1.269,0.793c0.106,0,0.213-0.013,0.319-0.037c0.642-0.148,1.095-0.719,1.095-1.378V18.985l13.038-11.89
				C35.731,6.703,35.877,6.084,35.666,5.539z M11.284,11.667l-2.519,2.979V9.611V6.346l3.514,4.144L11.284,11.667z M4.437,8.196
				l1.499-1.8v1.8H4.437z M19.434,27.156l-1.884-3.848l1.884-1.662V27.156z M15.586,21.247l-6.019-3.168l5.645-6.677V11.4
				l3.327-3.935h12.159L15.586,21.247z'
                              />
                            </SvgIcon>
                            <span className={classes.pageLinkButtonLabelText}>
                              Origami
                            </span>
                          </>
                        }
                      />
                      <PageLinkButton
                        to='/robotics'
                        label={
                          <>
                            <SvgIcon
                              viewBox='0 0 45.342 45.342'
                              fontSize='small'
                            >
                              <path
                                d='M40.462,19.193H39.13v-1.872c0-3.021-2.476-5.458-5.496-5.458h-8.975v-4.49c1.18-0.683,1.973-1.959,1.973-3.423
		c0-2.182-1.771-3.95-3.951-3.95c-2.183,0-3.963,1.769-3.963,3.95c0,1.464,0.785,2.74,1.965,3.423v4.49h-8.961
		c-3.021,0-5.448,2.437-5.448,5.458v1.872H4.893c-1.701,0-3.091,1.407-3.091,3.108v6.653c0,1.7,1.39,3.095,3.091,3.095h1.381v1.887
		c0,3.021,2.427,5.442,5.448,5.442h2.564v2.884c0,1.701,1.393,3.08,3.094,3.08h10.596c1.701,0,3.08-1.379,3.08-3.08v-2.883h2.578
		c3.021,0,5.496-2.422,5.496-5.443V32.05h1.332c1.701,0,3.078-1.394,3.078-3.095v-6.653C43.54,20.601,42.165,19.193,40.462,19.193z
		 M10.681,21.271c0-1.999,1.621-3.618,3.619-3.618c1.998,0,3.617,1.619,3.617,3.618c0,1.999-1.619,3.618-3.617,3.618
		C12.302,24.889,10.681,23.27,10.681,21.271z M27.606,34.473H17.75c-1.633,0-2.957-1.316-2.957-2.951
		c0-1.633,1.324-2.949,2.957-2.949h9.857c1.633,0,2.957,1.316,2.957,2.949S29.239,34.473,27.606,34.473z M31.056,24.889
		c-1.998,0-3.618-1.619-3.618-3.618c0-1.999,1.62-3.618,3.618-3.618c1.999,0,3.619,1.619,3.619,3.618
		C34.675,23.27,33.055,24.889,31.056,24.889z'
                              />
                            </SvgIcon>
                            <span className={classes.pageLinkButtonLabelText}>
                              Robotics
                            </span>
                          </>
                        }
                      />
                      <PageLinkButton
                        to='/printing'
                        label={
                          <>
                            <PrintRoundedIcon fontSize='small' />
                            <span className={classes.pageLinkButtonLabelText}>
                              3D Printing
                            </span>
                          </>
                        }
                      />
                    </ThickTreeItem>
                    <ThickTreeItem
                      nodeId='6'
                      label={
                        <>
                          <FolderRoundedIcon fontSize='small' />
                          <span className={classes.treeFolderLabel}>Other</span>
                        </>
                      }
                    >
                      <PageLinkButton
                        to='/cubing'
                        label={
                          <>
                            <SvgIcon viewBox='0 0 512 512' fontSize='small'>
                              <rect
                                y='182.044'
                                width='147.911'
                                height='147.911'
                              />
                              <rect
                                x='182.044'
                                width='147.911'
                                height='147.911'
                              />
                              <rect
                                x='182.044'
                                y='182.044'
                                width='147.911'
                                height='147.911'
                              />
                              <rect
                                x='182.044'
                                y='364.089'
                                width='147.911'
                                height='147.911'
                              />
                              <path d='M460.8,0h-96.711v147.911H512V51.2C512,22.967,489.033,0,460.8,0z' />
                              <rect
                                x='364.089'
                                y='182.044'
                                width='147.911'
                                height='147.911'
                              />
                              <path d='M0,364.089V460.8C0,489.033,22.967,512,51.2,512h96.711V364.089H0z' />
                              <path d='M364.089,364.089V512H460.8c28.233,0,51.2-22.967,51.2-51.2v-96.711H364.089z' />
                              <path d='M51.2,0C22.967,0,0,22.967,0,51.2v96.711h147.911V0H51.2z' />
                            </SvgIcon>
                            <span className={classes.pageLinkButtonLabelText}>
                              Cubing
                            </span>
                          </>
                        }
                      />
                      <PageLinkButton
                        to='/chess'
                        label={
                          <>
                            <SvgIcon viewBox='-96 0 512 512' fontSize='small'>
                              <path d='M105.1 224H80a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h16v5.49c0 44-4.14 86.6-24 122.51h176c-19.89-35.91-24-78.51-24-122.51V288h16a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-25.1c29.39-18.38 49.1-50.78 49.1-88a104 104 0 0 0-208 0c0 37.22 19.71 69.62 49.1 88zM304 448H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z' />
                            </SvgIcon>
                            <span className={classes.pageLinkButtonLabelText}>
                              Chess
                            </span>
                          </>
                        }
                      />
                    </ThickTreeItem>
                  </ThickTreeItem>
                  <ThickTreeItem
                    nodeId='7'
                    label={
                      <>
                        <WidgetsRoundedIcon fontSize='small' />
                        <span className={classes.treeFolderLabel}>Other</span>
                      </>
                    }
                  >
                    <PageLinkButton
                      to='/hint'
                      label={
                        <>
                          <SvgIcon viewBox='0 0 44.286 140.36' fontSize='small'>
                            <g transform='translate(-44.362 -20.916)'>
                              <path d='m59.856 20.916c-5.0153 0-4.7951 2.4691-4.7951 2.4691v8.5452c0 0.90201 1.5549 0.8754 1.5549 0.8754l-1.4836 4.6318c-0.1899 0.56969-0.29146 1.2537-0.29146 1.2537l-1.4888 16.359s-0.01745 2.1136-4.9139 7.2636c-4.2269 4.2351-4.0752 9.7322-4.0752 9.7322v73.251s-0.15988 15.106 6.5794 15.106c0 0 1.4856 0.0699 3.1228-1.28 0.24966-0.2085 0.41274-0.32167 0.62012-0.30592 0 0 0.25556 8e-3 0.44907 0.39429 0.20314 0.40617 0.92039 2.0645 2.2784 2.0645h3.3926s1.2579 0.0461 2.0412-0.61856c0.78332-0.66465 2.5935-3.252 3.6587-3.252 1.0652 0 2.8754 2.5874 3.6587 3.252 0.78333 0.66462 2.0412 0.61856 2.0412 0.61856h3.3926c1.358 0 2.0753-1.6583 2.2784-2.0645 0.19351-0.38644 0.44907-0.39429 0.44907-0.39429 0.20738-0.0157 0.37047 0.0974 0.62012 0.30592 1.6372 1.3499 3.1228 1.28 3.1228 1.28 6.7393 0 6.5794-15.106 6.5794-15.106v-73.251s0.15172-5.4972-4.0752-9.7322c-4.8965-5.1501-4.9139-7.2636-4.9139-7.2636l-1.4888-16.359s-0.10156-0.68398-0.29145-1.2537l-1.4836-4.6318s1.5549 0.02661 1.5549-0.8754v-8.5452s0.22025-2.4691-4.7951-2.4691h-6.6492z' />
                            </g>
                          </SvgIcon>
                          <span className={classes.pageLinkButtonLabelText}>
                            Hint
                          </span>
                        </>
                      }
                    />
                    <PageLinkButton
                      to='/ri3d'
                      label={
                        <>
                          <SvgIcon viewBox='0 0 53.709 53.605' fontSize='small'>
                            <g transform='translate(-50.416 -154.22)'>
                              <path d='m71.13 193.01c-1.2298-3.9039-11.475-8.9449-11.475-8.9449 0.60369-0.57724 1.2234-1.9394 1.2234-1.9394 1.4421-2.9873 2.6565-4.4075 3.5073-5.3803s2.2001-1.5037 2.6532-1.6758c0.45312-0.17215 1.1336-0.81829 1.1336-0.81829s11.027 7.6581 15.015 6.4472c-1.0316-4.0021-11.189-10.204-11.189-10.204 1.752-1.6946 1.4558-1.5088 2.0167-2.3951 0.56089-0.88632 1.9653-2.3576 3.3414-3.6593 1.3762-1.3018 3.1832-1.4449 3.4311-1.5066 0.24787-0.0618 0.62868-0.35407 0.62868-0.35407s7.5201 4.7213 10.669 4.4724c-0.86979-2.5996-7.1549-6.9375-7.1549-6.9375 3.8312-2.7011 8.9112-4.7502 8.9112-4.7502s5.76-2.661 8.9702 0.1459c3.2102 2.8069-0.28601 10.051-1.7798 13.174-1.4938 3.1235-6.7713 10.181-12.242 16.605-5.4708 6.4233-16.147 14.579-16.147 14.579s-15.732 11.758-20.884 6.6929c-5.1523-5.0647 6.1899-20.19 6.1899-20.19s8.7116 7.0384 13.182 6.6388z' />
                            </g>
                          </SvgIcon>
                          <span className={classes.pageLinkButtonLabelText}>
                            RI3D
                          </span>
                        </>
                      }
                    />
                    <PageLinkButton
                      to='/misc'
                      label={
                        <>
                          <WidgetsRoundedIcon fontSize='small' />
                          <span className={classes.pageLinkButtonLabelText}>
                            RI3D
                          </span>
                        </>
                      }
                    />
                  </ThickTreeItem>
                </TreeView>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  className={classes.profileRowDiv}
                  style={{ height: '56px' }}
                >
                  <RippleBadge
                    overlap='circle'
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant='dot'
                    style={{ marginLeft: '10px' }}
                  >
                    <Avatar
                      alt={'Dylon Tjanaka'}
                      src={pfp}
                      className={classes.smallAvatar}
                    />
                  </RippleBadge>
                  <span className={classes.avatarName}>Dylon Tjanaka</span>
                  <VerifiedUserRoundedIcon fontSize='small' />
                </div>
                <div className={classes.profileRowDiv}>
                  <LocationOnRoundedIcon
                    className={classes.locationChipIcon}
                    fontSize='small'
                  />
                  <span style={{ marginLeft: '5px' }}>Santa Clara, CA</span>
                </div>
                <div
                  className={`${classes.attributeChip} ${classes.inProgresChip}`}
                >
                  MS CS @ UCLA
                </div>

                <div
                  className={`${classes.attributeChip} ${classes.successChip}`}
                >
                  BS CS @ UCLA
                </div>

                <ChipBadge color='primary' badgeContent={'x3'}>
                  <div
                    className={`${classes.attributeChip} ${classes.successChip}`}
                    style={{ marginBottom: '10px' }}
                  >
                    Google intern
                  </div>
                </ChipBadge>
                {/* disableGutters only available in MUI v5 (material-ui (v4) --> mui (v5)): `npm install @mui/material @emotion/react @emotion/styled` 
                    only Accordion components are v5 right now - will take a look at other differences between v4 and v5 later */}
                <Accordion
                  expanded={accordionExpanded === 'panel1'}
                  onChange={handleAccordionChange('panel1')}
                  square={true}
                  disableGutters={true}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreRoundedIcon className={classes.expandIcon} />
                    }
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                    className={classes.accordionSummary}
                  >
                    <Typography>Movies & Shows</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Typography>
                      {/*The Matrix, Constantine, Fight Club, Scarface*/}Cars 2
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={accordionExpanded === 'panel2'}
                  onChange={handleAccordionChange('panel2')}
                  square={true}
                  disableGutters={true}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreRoundedIcon className={classes.expandIcon} />
                    }
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                    className={classes.accordionSummary}
                  >
                    <Typography>Music</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Typography>Part-Time Lover by Dabin</Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={accordionExpanded === 'panel3'}
                  onChange={handleAccordionChange('panel3')}
                  square={true}
                  disableGutters={true}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreRoundedIcon className={classes.expandIcon} />
                    }
                    aria-controls='panel3bh-content'
                    id='panel3bh-header'
                    className={classes.accordionSummary}
                  >
                    <Typography>Quotes</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Typography>
                      “You must take your opponent into a deep dark forest where
                      2+2=5, and the path leading out is only wide enough for
                      one.” Mikhaïl Tal
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={accordionExpanded === 'panel4'}
                  onChange={handleAccordionChange('panel4')}
                  square={true}
                  disableGutters={true}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreRoundedIcon className={classes.expandIcon} />
                    }
                    aria-controls='panel4bh-content'
                    id='panel4bh-header'
                    className={classes.accordionSummary}
                  >
                    <Typography>Colors</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Typography>This green is pretty elite:</Typography>
                    <div className={classes.favoriteColorBox}></div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={accordionExpanded === 'panel5'}
                  onChange={handleAccordionChange('panel5')}
                  square={true}
                  disableGutters={true}
                  className={`${classes.accordion} ${classes.showBottomShadow}`}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreRoundedIcon className={classes.expandIcon} />
                    }
                    aria-controls='panel5bh-content'
                    id='panel5bh-header'
                    className={classes.accordionSummary}
                  >
                    <Typography>Games</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Typography>Chess</Typography>
                  </AccordionDetails>
                </Accordion>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <FixedBottomNavigation
                  labels1={quarterLabels}
                  labels2={yearLabels}
                >
                  <ClassSchedule />
                </FixedBottomNavigation>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div className={classes.boxes}>
                  {[...Array(10000 + 1).keys()].slice(1).map((x) => (
                    <div
                      className={`${classes.box} ${
                        x <= 5
                          ? classes.boxTop
                          : x >= 9996
                          ? classes.boxBottom
                          : ''
                      } ${
                        x % 5 === 1
                          ? classes.boxLeft
                          : x % 5 === 0
                          ? classes.boxRight
                          : ''
                      }`}
                      id={'box-' + x}
                    >
                      <button
                        type='button'
                        onClick={() => toggleModal(true, x)}
                        className={classes.boxButton}
                      >
                        <Typography variant='button'>{x}</Typography>
                      </button>
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <div className={classes.settingsContainer}>
                  <Rating
                    name='website-rating'
                    value={rating}
                    onChange={(event, newRating) => {
                      setRating(newRating);
                    }}
                    precision={0.5}
                  />
                </div>
              </TabPanel>
            </SwipeableViews>
          </div>
        </Drawer>
        <AppBar position='fixed'>
          <Toolbar className={classes.toolbar} disableGutters={true}>
            <IconButton
              color='secondary'
              aria-label='menu'
              onClick={toggleDrawer(true)}
              focusVisibleClassName={classes.menuButtonFocused}
            >
              <MenuRoundedIcon />
            </IconButton>
            <Link to='/'>
              <img
                src={logo}
                className={classes.homeIcon}
                alt='Dylon Tjanaka DT home icon'
              />
            </Link>
            <Typography
              variant='h6'
              className={classes.title}
              style={{ fontSize: '1rem' }}
              id='headerTitle'
            >
              {window.innerWidth < 600 ? 'Dylon Tjanaka' : props.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {/* https://material-ui.com/components/app-bar/#fixed-placement */}
        <Toolbar classes={{ regular: classes.fixedPlacementToolbarRegular }} />
      </>
    );
  }
}

export default Header;

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
// const text = '     ';

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-512', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}

// digestMessage(text).then((digestHex) => console.log(digestHex));
// "e524ccd3ddf10b82db1c2f36d38ceeda6ed76eecb56d3cb326cd298d96706deef8cb895322343edb5069a068223c590cee6a821fc424a7e785b03d6c82b9e79d"

// https://stackoverflow.com/a/26317465
