import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const Title = 'Dylon Tjanaka | Snake: Origins';

const useStyles = makeStyles((theme) => ({
  snakeOriginsIframe: {
    width: '100%',
    height: '100vh',
    border: 'none',
    // https://stackoverflow.com/a/12726445
    verticalAlign: 'bottom',
  },
}));

function SnakeOrigins(props) {
  const classes = useStyles();

  return (
    <>
      <Helmet defer={false}>
        <title>{Title}</title>
      </Helmet>
      <iframe
        src='https://snake-origins.netlify.app'
        className={classes.snakeOriginsIframe}
        title='Snake: Origins game'
      ></iframe>
    </>
  );
}

export default SnakeOrigins;
